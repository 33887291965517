import { FieldArray, Formik } from 'formik';
import React, { forwardRef, useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import { api_urls } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';
import {maintenanceDefaultValue} from './EditGeneralDetails'
import MaintenanceRow from './MaintenanceRow';

export const GeneralDetails = forwardRef((props, ref) => {
  console.log('props in aircraft', props);
  const navigate = useNavigate();
  const [getColor, setColor] = useState();
  const [addMaintenance, setAddMaintenance] = useState(false);
  const [getPilots, setPilots] = useState([
    {
      label: '50hr',
      value: '50hr',
    },
    {
      label: '100hr',
      value: '100hr',
    },
    {
      label: '150hr',
      value: '150hr',
    },
    {
      label: '30days',
      value: '30days',
    },
  ]);
  const [pilotVal, setpilotVal] = useState(null);

  const schema = Yup.object().shape({
    // aircraftName: Yup.string().required('Aircraft name is required'),
    tailNumber: Yup.string().required('Tail number is required'),
    lastMaintenance: Yup.date().required('Last weighed is required'),
    TotalTime: Yup.number().required('Aircraft total time is required'),
    ColHobs: Yup.number().required('Collective Hobbs is required'),
    // Model: Yup.string().required('Model is required'),
  });

  const onColorChange = (newColor) => {
    console.log('newcolor', newColor.hex);
    setColor(newColor.hex);
  };
  const handlePilotsDropdown = (event) => {
    setpilotVal(event);
  };
  useEffect(() => {
    setpilotVal({
      label: props.profileDetails.maintenance_due,
      value: props.profileDetails.maintenance_due,
    });
  }, [props.profileDetails.maintenance_due]);

  return (
    <>
      <Formik
        innerRef={ref}
        validationSchema={schema}
        initialValues={
          Object.keys(props.profileDetails).length > 0
            ? {
                aircraftName: props.profileDetails.name || '',
                tailNumber: props.profileDetails.tail_number || '',
                Mark: props.profileDetails.mark || '',
                Model: props.profileDetails.model || '',
                Notes: props.profileDetails.notes || '',
                Weight: props.profileDetails.weight || null,
                WeighNotes: props.profileDetails.weight_notes || null,
                longArm: props.profileDetails.longitudinal_arm || null,
                latArm: props.profileDetails.lateral_arm || null,
                lastMaintenance: props.profileDetails.last_weighed
                  ? props.profileDetails.last_weighed.split(' ')[0]
                  : '',
                ColHobs: props.profileDetails.collective_hobbs || '',
                TotalTime: props.profileDetails.total_time || '',
                maintenance:
                  props.profileDetails.maintenance.length > 0
                    ? props.profileDetails.maintenance
                    : maintenanceDefaultValue,
              }
            : {
                aircraftName: '',
                tailNumber: '',
                Mark: '',
                Model: '',
                Notes: '',
                Weight: null,
                WeighNotes: '',
                CompletedBy: '',
                longArm: null,
                latArm: null,
                lastMaintenance: '',
                ColHobs: '',
                NxtmxDue: '',
                TotalTime: '',
                maintenance:maintenanceDefaultValue
              }
        }
        enableReinitialize
        onSubmit={async (values, actions) => {
          console.log('values inside on submit', values);
          // if (getColor === undefined) {
          //   setshowColorError(true);
          //   return;
          // }
          const url = new URL(window.location.href);
          const id = url.pathname.split('/').pop();
          let apiurl = api_urls.aircraftProfile + id + '/';
          if (!props.create) {
            try {
              let body = {
                // name: values.aircraftName,
                tail_number: values.tailNumber,
                mark: values.Mark,
                organization:
                  sessionStorage.getItem('organization_id') === 'null'
                    ? 2
                    : sessionStorage.getItem('organization_id'),
                color: getColor,
                weight: values.Weight,
                weight_notes: values.WeighNotes,
                model: values.Model,
                notes: values.Notes,
                longitudinal_arm: values.longArm,
                lateral_arm: values.latArm,
                last_weighed: `${values.lastMaintenance}T00:00`,
                collective_hobbs: values.ColHobs,
                total_time: values.TotalTime,
                maintenance_due: pilotVal.value,
                total_time: values.TotalTime,
                maintenance: values.maintenance.map(item => {
                  const maintenanceItem = {
                    maintenance_due: item.maintenance_due,
                    next_mx_due: item.next_mx_due,
                  };
                
                  if (item.completed_by) {
                    maintenanceItem.completed_by = item.completed_by;
                  }
                
                  return maintenanceItem;
                }),
              };

              const response = await ApiRequests(apiurl, 'PATCH', body, null);
              if (response.status === 200) {
                props.savedSuccessfully();
                toast.success('Aircraft updated successfully');
                // navigate('/dashboard/aircrafts', { replace: true });
              }
            } catch (error) {
              console.error('There was an error!', error);
              toast.error('Error in updating Aircraft');
            }
          } else {
            let body = {
              // name: values.aircraftName,
              tail_number: values.tailNumber,
              model: values.Model,
              notes: values.Notes,
              mark: values.Mark,
              organization:
                sessionStorage.getItem('organization_id') === 'null'
                  ? 2
                  : sessionStorage.getItem('organization_id'),
              weight: values.Weight,
              weight_notes: values.WeighNotes,
              color: getColor,
              longitudinal_arm: values.longArm,
              lateral_arm: values.latArm,
              last_weighed: `${values.lastMaintenance}T00:00`,
              collective_hobbs: values.ColHobs,
              total_time: values.TotalTime,
              maintenance_due: pilotVal.value,
              total_time: values.TotalTime,
              maintenance: values.maintenance.map(item => {
                const maintenanceItem = {
                  maintenance_due: item.maintenance_due,
                  next_mx_due: item.next_mx_due,
                };
              
                if (item.completed_by) {
                  maintenanceItem.completed_by = item.completed_by;
                }
              
                return maintenanceItem;
              }),
            };
            props.storeNewUserDetails(body);
          }
          actions.setSubmitting(false);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form className="" noValidate style={{ padding: '15px' }}>
            <Row
              style={{
                border: '1px solid #e5e7eb',
                borderRadius: '8px',
                paddingBottom: '10px',
                marginBottom: '30px',
              }}>
              <h3 className="personalLabels">Aircraft details</h3>
              <hr />
              <div>
                {/* <Form.Group
                  controlId="aircraftName"
                  className={`form-floating  viewPilot-Box  mb-3 ${touched.aircraftName && errors.aircraftName
                    ? 'has-validation-error'
                    : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="aircraftName"
                    placeholder="Aircraft Name"
                    value={values.aircraftName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    isInvalid={touched.aircraftName && errors.aircraftName}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Aircraft Name
                  </label>
                  <Form.Control.Feedback type="invalid">
                    {errors.aircraftName}
                  </Form.Control.Feedback>
                </Form.Group> */}
                <Form.Group
                  controlId="tailNumber"
                  className={`form-floating viewPilot-Box   mb-3 ${
                    touched.tailNumber && errors.tailNumber
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="tailNumber"
                    placeholder="Tail number"
                    value={values.tailNumber}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.tailNumber && errors.tailNumber}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Tail number
                  </label>
                  <Form.Control.Feedback type="invalid">
                    {errors.tailNumber}
                  </Form.Control.Feedback>
                </Form.Group>
                {/* <Form.Group
                  controlId="Mark"
                  className={`form-floating viewPilot-Box  mb-3 ${touched.Mark && errors.Mark ? 'has-validation-error' : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="Mark"
                    placeholder="Mark"
                    value={values.Mark}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.Mark && errors.Mark}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Mark
                  </label>

                  {touched.Mark && errors.Mark ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Mark}
                  </Form.Control.Feedback>
                </Form.Group> */}
                <Form.Group
                  controlId="Model"
                  className={`form-floating viewPilot-Box  mb-3 ${
                    touched.Model && errors.Model ? 'has-validation-error' : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="Model"
                    placeholder="Model"
                    value={values.Model}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.Model && errors.Model}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Model
                  </label>

                  {touched.Model && errors.Model ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Model}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="Notes"
                  className={`form-floating viewPilot-Box  mb-3 ${
                    touched.Notes && errors.Notes ? 'has-validation-error' : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="Notes"
                    placeholder="Notes"
                    value={values.Notes}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.Notes && errors.Notes}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Notes
                  </label>

                  {touched.Notes && errors.Notes ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Notes}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>
            <Row
              style={{
                border: '1px solid #e5e7eb',
                borderRadius: '8px',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h4 className="personalLabels">Weight</h4>
              <hr />
              <div>
                <Form.Group
                  controlId="Weight"
                  className={`form-floating  viewPilot-Box  mb-3 ${
                    touched.Weight && errors.Weight
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="number"
                    name="Weight"
                    placeholder="Weight"
                    value={values.Weight}
                    onChange={handleChange}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onBlur={handleBlur}
                    isInvalid={touched.Weight && errors.Weight}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Weight
                  </label>

                  {touched.Weight && errors.Weight ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Weight}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="longArm"
                  className={`form-floating viewPilot-Box   mb-3 ${
                    touched.longArm && errors.longArm
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="number"
                    name="longArm"
                    placeholder="Longitudinal Arm"
                    value={values.longArm}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.longArm && errors.longArm}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Longitudinal Arm
                  </label>

                  {touched.longArm && errors.longArm ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.longArm}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="latArm"
                  className={`form-floating viewPilot-Box  mb-3 ${
                    touched.latArm && errors.latArm
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="number"
                    name="latArm"
                    placeholder="Lateral Arm"
                    value={values.latArm}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.latArm && errors.latArm}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Lateral Arm
                  </label>

                  {touched.latArm && errors.latArm ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.latArm}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="lastMaintenance"
                  className={`form-floating viewPilot-Box mb-3 ${
                    touched.lastMaintenance && errors.lastMaintenance
                      ? 'has-validation-error date-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="date"
                    min="1600-12-31"
                    max="2099-12-31"
                    name="lastMaintenance"
                    placeholder="Last weighed"
                    value={values.lastMaintenance}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.lastMaintenance && errors.lastMaintenance
                    }
                  />
                  <label for="floatingInput" className="greyLabel">
                    Last weighed
                  </label>

                  {touched.lastMaintenance && errors.lastMaintenance ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.lastMaintenance}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="WeighNotes"
                  className={`form-floating viewPilot-Box  mb-3 ${
                    touched.WeighNotes && errors.WeighNotes
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="WeighNotes"
                    placeholder="Notes"
                    value={values.WeighNotes}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.WeighNotes && errors.WeighNotes}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Notes
                  </label>

                  {touched.WeighNotes && errors.WeighNotes ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.WeighNotes}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>

            <Row
              style={{
                border: '1px solid #e5e7eb',
                borderRadius: '8px',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h4 className="personalLabels">Aircraft Maintenance</h4>
              <hr />

              <FieldArray name="maintenance">
                {() => (
                  <div>
                    {values.maintenance.map((row, index) => {
                      return (
                        <MaintenanceRow
                          key={index}
                          maintenanceDue={row.maintenance_due}
                          nxtmxDue={row.next_mx_due}
                          completedBy={row.completed_by}
                          readOnly={props.readOnly}
                          touched={touched}
                          errors={errors}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          fieldNames={{
                            maintenanceDue: `maintenance[${index}].maintenance_due`,
                            nxtmxDue: `maintenance[${index}].next_mx_due`,
                            completedBy: `maintenance[${index}].completed_by`,
                          }}
                        />
                      );
                    })}
                  </div>
                )}
              </FieldArray>
            </Row>

            <Row
              style={{
                border: '1px solid #e5e7eb',
                borderRadius: '8px',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h4 className="personalLabels">Aircraft Hours</h4>
              <hr />
              <div>
                <Form.Group
                  controlId="ColHobs"
                  className={`form-floating viewPilot-Box  mb-3 ${
                    touched.ColHobs && errors.ColHobs
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="number"
                    name="ColHobs"
                    placeholder="Collective Hobbs"
                    value={values.ColHobs}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.ColHobs && errors.ColHobs}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Collective Hobbs
                  </label>

                  {touched.ColHobs && errors.ColHobs ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.ColHobs}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="TotalTime"
                  className={`form-floating viewPilot-Box  mb-3 ${
                    touched.TotalTime && errors.TotalTime
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="number"
                    name="TotalTime"
                    placeholder="Aircraft Total Time"
                    value={values.TotalTime}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.TotalTime && errors.TotalTime}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Aircraft Total Time
                  </label>

                  {touched.TotalTime && errors.TotalTime ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.TotalTime}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>

            <Row
              style={{
                borderRadius: '5px',
                border: '1px solid #E5E7EB',
                paddingBottom: '30px',
                marginBottom: '10px',
              }}>
              <h4 className="personalLabels">Pick a color for Aircraft</h4>
              <hr />
              <div className="color-picker-wrapper">
                <div className="color-hex-cont">
                  <div className="color-hex-val">
                    <div
                      style={{
                        background: getColor || values.color,
                        width: 56,
                        height: 43,
                        color: getColor || values.color,
                      }}>
                      {getColor || values.color}
                    </div>
                    <div className="color-hex">{getColor || values.color}</div>
                  </div>
                </div>
                <SketchPicker
                  color={getColor || values.color}
                  onChange={onColorChange}
                  className="p-4 color-picker"
                />
              </div>
            </Row>
          </form>
        )}
      </Formik>
    </>
  );
});
